import {
  useTonWallet,
  useIsConnectionRestored,
  useTonConnectUI,
  useTonAddress,
} from "@tonconnect/ui-react";
import { useState, useContext } from "react";

import { BackendTokenContext } from "./BackendTokenContext";
import { backendAuth } from "./BackendAuth";

import styles from "./index.module.scss";

export const SendTx = () => {
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const [txInProgress, setTxInProgress] = useState(false);
  const [withdrawInProgress, setWithdrawInProgress] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(""); // Новый state для кода
  const [withdrawId, setWithdrawId] = useState(null); // State для ID вывода
  const address = useTonAddress();

  const { jwtToken } = useContext(BackendTokenContext);

  let content;
  switch (true) {
    case !isConnectionRestored:
      content = "Loading...";
      break;
    case txInProgress:
      content = "Tx in progress";
      break;
    case !!wallet:
      content = "TopUp";
      break;
    default:
    case !wallet:
      content = "Connect Wallet";
      break;
  }

  async function handleWithdraw() {
    const token = 5; // ID токена
    const amount = 0.5; // Сумма для вывода

    if (!jwtToken) {
      alert("JWT Token отсутствует!");
      return;
    }

    try {
      // Шаг 1: Создание вывода
      const withdrawResponse = await backendAuth.createWithdraw(
        token,
        amount,
        jwtToken
      );

      console.log("Withdraw Response:", withdrawResponse);

      if (!withdrawResponse || typeof withdrawResponse.id !== "number") {
        throw new Error("Invalid withdraw ID received from server.");
      }

      setWithdrawId(withdrawResponse.id); // Сохраняем ID вывода
    } catch (error) {
      console.error("Ошибка вывода:", error);
      alert(`Ошибка вывода: ${error.message}`);
    }
  }

  async function handleVerify() {
    if (!withdrawId) {
      alert("ID вывода отсутствует!");
      return;
    }

    const numericCode = Number(confirmationCode);
    if (isNaN(numericCode)) {
      alert("Invalid code: must be a numeric value.");
      return;
    }

    try {
      const verifyResponse = await backendAuth.verifyWithdraw(
        withdrawId,
        numericCode,
        jwtToken
      );

      console.log("Verify Response:", verifyResponse);

      if (verifyResponse && verifyResponse.transaction_id) {
        alert(
          `Вывод успешно выполнен! Транзакция: ${verifyResponse.transaction_id}`
        );
      } else {
        alert(`Ошибка: ${JSON.stringify(verifyResponse)}`);
      }
    } catch (error) {
      if (error.response) {
        // Если ошибка с сервера, получить тело ответа
        const errorText = await error.response.text();
        alert(`Ошибка подтверждения: ${errorText}`);
      } else if (error.message) {
        // Обработка стандартных ошибок
        alert(`Ошибка подтверждения: ${error.message}`);
      } else {
        // Неизвестная ошибка
        alert("Произошла неизвестная ошибка.");
      }

      console.error("Ошибка подтверждения вывода:", error);
    }
  }

  const onClick = async () => {
    if (!wallet) {
      tonConnectUI.connectWallet();
    } else {
      setTxInProgress(true);
      try {
        await tonConnectUI.sendTransaction({
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: "EQD5mxRgCuRNLxKxeOjG6r14iSroLF5FtomPnet-sgP5xNJb",
              amount: "5000000",
              payload: "te6ccgEBAQEADwAAGgAAAAAxMzcwNzY5OTg=",
            },
          ],
        });

        // 2. Добавление токенов
        const response = await backendAuth.addTokens(5, 0.05, jwtToken);
        console.log("Tokens added successfully:", response);
        alert("Transaction completed successfully!");
      } catch (e) {
        console.error("Transaction error:", e);
        alert("Transaction failed.");
      } finally {
        setTxInProgress(false);
      }
    }
  };

  return (
    <div
      className={styles.tonConnectContainer}
      style={{ marginBottom: "20px" }}
    >
      <button
        className={styles.connectButton}
        disabled={!isConnectionRestored || txInProgress}
        onClick={onClick}
      >
        {content}
      </button>

      <button
        onClick={handleWithdraw}
        className={styles.connectButton}
        disabled={txInProgress}
      >
        Create Withdraw
      </button>

      {withdrawId && (
        <div
          className={styles.confirmationContainer}
          style={{ display: "flex" }}
        >
          <input
            id="confirmationCode"
            type="text"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            className={styles.inputField}
            placeholder="Введите 6-значный код"
          />
          <button
            className={styles.connectButton}
            onClick={handleVerify}
            disabled={withdrawInProgress || !confirmationCode}
          >
            Verify Withdraw
          </button>
        </div>
      )}
    </div>
  );
};
